<template>
  <div class="sd-device-card py-2">
    <div class="sd-device-card-top px-3 d-flex justify-content-between mb-2">
      <span
        class="sd-device-card-code font-17"
        @click="$emit('device-settings', device)"
      >
        <b>{{ serial_number }} - </b> {{ name }}</span>
      <b @click="$emit('device-settings', device)"><i class="sdicon-gear text-primary" /></b>
    </div>
    <div class="sd-device-content px-3">
      <div class="d-flex justify-content-between mt-1">
        <div :class="`sd-device-status  font-13 uppercase bg-${getStatusBgColor(status)} text-white px-2 py-1`">
          <b>{{ status === DEVICE_STATUS.IN_USE ? "IN USE" : status }}</b>
        </div>
        <div>{{ provider }}</div>
      </div>
      <div class="sd-device-address font-13 my-2">
        <b><a
             v-if="propertyAddress"
             :href="`/properties/${device.unit.property.id}/units/${device.unit.id}`"
           > {{ propertyAddress }}</a>
          {{ unitName ? ` - ${unitName}` : "" }}</b>
      </div>
    </div>
    <div class="sd-device-buttons d-flex justify-content-between p-3">
      <ElButton
        type="primary"
        size="small"
        @click="$emit('open-code-dialog', device)"
      >
        Send Code
      </ElButton>
      <ElButton
        size="small"
        :class="`${isAassignedDevice ? 'sd-device-card-unassigned' : ''}`"
        :disabled="status === DEVICE_STATUS.IN_USE"
        :loading="isHandledDevice"
        @click="handleAssignButtonClick(device, status)"
      >
        {{ isAassignedDevice ? "Unassign" : "Assign" }}
      </ElButton>
    </div>
  </div>
</template>

<script>
import { ref } from '@vue/composition-api';
import get from 'lodash.get';
import { DEVICE_STATUS } from '@/constants/Device';

export default {
  name: 'DeviceCard',
  props: {
    device: {
      type: Object,
      required: true,
    },
  },
  setup(props, context) {
    const {
     name, serial_number, status, isHandled, provider,
    } = props.device;
    const propertyAddress = get(props.device, 'unit.property.short_address');
    const unitName = get(props.device, 'unit.name');
    const isHandledDevice = ref(isHandled);
    const isAassignedDevice = status !== DEVICE_STATUS.AVAILABLE;

    function getStatusBgColor(status) {
      switch (status) {
        case DEVICE_STATUS.IN_USE:
          return 'green';
        case DEVICE_STATUS.ASSIGNED:
          return 'primary';
        case DEVICE_STATUS.AVAILABLE:
          return 'gray';
        default:
          return 'green';
      }
    }

    function handleAssignButtonClick(device, status) {
      if (status !== DEVICE_STATUS.ASSIGNED) {
        context.emit('device-settings', device);
      } else {
        isHandledDevice.value = true;
        context.emit('unassign-device', device, true);
      }
    }

    return {
      name,
      serial_number,
      propertyAddress,
      status,
      isAassignedDevice,
      unitName,
      provider,
      DEVICE_STATUS,
      isHandledDevice,
      getStatusBgColor,
      handleAssignButtonClick,
    };
  },
};
</script>

<style lang="scss">
.sd-device-card {
  background: $white;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.1);
  border-radius: 6px;

  .sdicon-gear,
  .sd-device-card-code {
    cursor: pointer;
  }

  .sd-device-status {
    border-radius: 5px;
  }

  .sd-device-buttons {
    border-top: 1px solid gray-color("light");
  }

  .sd-device-card-unassigned,
  .sd-device-card-unassigned:hover {
    border-color: theme-color("danger");
    color: theme-color("danger");
    background: none;
  }
}
</style>
