<template>
  <SdPage id="device-page">
    <SdPageHeader
      title="Your Devices"
      class="mb-5 d-flex justify-content-center"
    />
    <div class="sd-device-main-content d-flex flex-column col-12">
      <SdSpinner
        v-if="isLoading"
        class="position-absolute mt-4"
      />
      <div
        v-if="!isLoading"
        class="filter-row mb-5 justify-content-end"
      >
        <ElSelect
          v-model="statusFilters.value"
          placeholder="Status"
          clearable
          collapse-tags
          multiple
          @change="handleStatusFilter($event)"
        >
          <ElOption
            v-for="(status, index) in statusFilters.options"
            :key="index"
            :label="status.label"
            :value="status.value"
            class="capitalize"
          />
        </ElSelect>
      </div>
      <template v-if="devicesToDisplay.length">
        <div class="sd-device-cards-panel pb-4 d-flex row">
          <DeviceCard
            v-for="device in devicesToDisplay"
            :key="`${device.status} + ${device.serial_number} + ${device.name} + ${getUnitId(device.unit)}`"
            :device="device"
            class="sd-device-card col-md-3 col-12 mb-3 mr-3"
            @device-settings="handleSettingsClick"
            @unassign-device="saveDevice"
            @open-code-dialog="openDeviceCodeDialog"
          />
        </div>
      </template>
      <div
        v-if="!devicesToDisplay.length && !isLoading"
        class="d-flex justify-content-center"
      >
        <h3 class="text-center d-block">
          You don't have any devices yet,
          <router-link :to="{ name: 'app.profile.business-settings', params: { section: 'integrations' } }">
            Setup your codebox integration
          </router-link>
        </h3>
      </div>
      <div class="sd-device-main-content-footer w-100">
        <span
          v-if="!isLoading && isCodeBoxProvider"
        >You can register additional codebox using your <a href="https://app.codeboxinc.com/Dashboard">Codeboxes Dashboard</a></span>
      </div>
    </div>
    <DeviceSettingsDialog
      v-if="uiFlags.isSettingsDialogVisible"
      :device="handledDevice"
      :used-units="unitsWithDevices"
      @close="settingsDialogClose"
      @save="saveDevice"
    />
    <DeviceCodeDialog
      v-if="uiFlags.isDeviceCodeDialogVisible && devicesToDisplay.length"
      :key="getUnitId()"
      :device="handledDevice"
      @close="deviceCodeDialogClose"
    />
  </SdPage>
</template>

<script>
import { reactive, ref } from '@vue/composition-api';
import { AccessHardware } from '@/constants/AccessHardware';
import get from 'lodash.get';
import { showErrorNotify, showSuccessNotify } from '@/utils/NotifyUtil';
import DeviceCard from './DeviceCard.vue';
import DeviceSettingsDialog from './DeviceSettingsDialog.vue';
import DeviceCodeDialog from './DeviceCodeDialog.vue';

export default {
  name: 'Lease',
  components: {
    DeviceCard,
    DeviceSettingsDialog,
    DeviceCodeDialog,
  },
  setup(props, context) {
    const { $store } = context.root;
    const devicesToDisplay = ref([]);
    const isLoading = ref(true);
    const isCodeBoxProvider = ref(false);
    const statusFilters = ref({});
    const unitsWithDevices = ref([]);
    let gridColumns = 1;

    const uiFlags = reactive({
      isSettingsDialogVisible: false,
      isDeviceCodeDialogVisible: false,
    });
    const handledDevice = ref({});

    let allDevices = [];

    getDevices();

    return {
      uiFlags,
      devicesToDisplay,
      isLoading,
      statusFilters,
      handledDevice,
      gridColumns,
      unitsWithDevices,
      getUnitId,
      handleStatusFilter,
      handleSettingsClick,
      settingsDialogClose,
      deviceCodeDialogClose,
      saveDevice,
      openDeviceCodeDialog,
      isCodeBoxProvider,
    };

    async function getDevices() {
      try {
        devicesToDisplay.value = await $store.dispatch('Device/get');
        allDevices = [...devicesToDisplay.value];
        isCodeBoxProvider.value = allDevices.some((device) => device.provider === AccessHardware.CODEBOX);
        unitsWithDevices.value = allDevices.filter((device) => get(device, 'unit.id')).map((device) => device.unit.id);
        gridColumns = devicesToDisplay.value.length >= 4 ? 4 : devicesToDisplay.value.length;
        document.documentElement.style.setProperty('--grid-columns', gridColumns);
        isLoading.value = false;
        const statusCounts = getStatusesAndCounts(allDevices);
        statusFilters.value = {
          value: [],
          options: Object.keys(statusCounts).map((status) => ({
            value: status,
            label: `${status.replace('_', ' ')} (${statusCounts[status]})`,
          })),
        };
      } catch (e) {
        showErrorNotify(context, e);
      }
    }

    function getStatusesAndCounts(devices) {
      return devices.reduce((statusesCounts, device) => {
        if (get(statusesCounts, device.status)) {
          statusesCounts[device.status] += 1;
        } else {
          statusesCounts[device.status] = 1;
        }
        return statusesCounts;
      }, {});
    }

    function handleStatusFilter(statusesToFilter) {
      const flattedStatuses = statusesToFilter.flat();
      if (flattedStatuses.length) {
        devicesToDisplay.value = allDevices.filter((device) => flattedStatuses.includes(device.status));
      } else {
        devicesToDisplay.value = allDevices;
      }
    }

    function getUnitId(unit) {
      return get(unit, 'property.id');
    }

    function handleSettingsClick(bubbledDevice, status) {
      handledDevice.value = bubbledDevice;
      uiFlags.isSettingsDialogVisible = true;
    }

    function settingsDialogClose() {
      uiFlags.isSettingsDialogVisible = false;
    }

    function openDeviceCodeDialog(bubbledDevice) {
      handledDevice.value = bubbledDevice;
      uiFlags.isDeviceCodeDialogVisible = true;
    }

    function deviceCodeDialogClose() {
      uiFlags.isDeviceCodeDialogVisible = false;
    }

    async function saveDevice(device, isUnassign) {
      const payload = {
        deviceId: device.id,
        name: device.name,
        unitId: device.unitId || null,
      };

      try {
        if (!isUnassign) {
          isLoading.value = true;
        }
        await context.root.$store.dispatch('Device/setDevice', payload);
        const successMessage = isUnassign ? 'Device was unassigned successfully' : 'Saved';
        showSuccessNotify(context, successMessage);
        await getDevices();
      } catch (error) {
        showErrorNotify(context, error.message || 'There was a problem saving this device settings');
      }
      uiFlags.isSettingsDialogVisible = false;
    }
  },
};
</script>
<style lang="scss">
#device-page {
  .filter-row {
    display: flex;
    text-align: right;
    width: 100%;
    border-bottom: 1px solid gray-color("light");
  }
  .el-tabs__header {
    margin: 0;
  }
  .sd-device-main-content {
    width: 83.1rem;
    margin: auto;
    overflow-x: auto;
  }
  .sd-device-cards-panel {
    height: auto;
    min-height: 24rem;
    .sd-device-card {
      max-width: 19.68rem;
      height: 166px;
    }
  }
  .el-select {
    height: 56px;
    background: $white;
    border-radius: 6px;
    .el-input__inner {
      background: $white;
    }
  }
}
</style>
